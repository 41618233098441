import {
  FormState,
  FormStatus,
} from '../../../../utils/state/initialStateFactory';
import { SubmissionResponse } from '@wix/forms-ui/types';
import { widgetDefaults } from '../../../../utils/bi/consts';
import { mapSubmissionToPartialBookRequest } from '../../../../utils/mappers/form-submission.mapper';
import { DialogType, ReservedPaymentOptionIds } from '../../../../types/types';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { Service } from '../../../../utils/mappers/service.mapper';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import {
  getSessionValues,
  setFieldsValuesInStorage,
} from '../../../../utils/storageFunctions';
import { CreateActionParams } from '../actions';
import {
  bookingsContactInfoSaveSuccess,
  bookingsPaymentMethodSelectionNextClicked,
} from '@wix/bi-logger-wixboost-ugc/v2';
import { BookErrorType } from '../../../../types/errors';

export type OnSubmit = (submission: SubmissionResponse) => void;

enum FlowType {
  OWNER,
  CHOOSE_PLAN,
  SINGLE_SESSION,
  PREVIEW,
}

export function createOnSubmitAction(
  actionFactoryParams: CreateActionParams,
): OnSubmit {
  return async (submissionResponse) => {
    const [state] = actionFactoryParams.getControllerState();
    const { wixSdkAdapter } = actionFactoryParams.context;
    const { status } = state;
    if (status === FormStatus.IDLE) {
      if (submissionResponse.state.valid) {
        switch (getCurrentFlow(state, wixSdkAdapter)) {
          case FlowType.CHOOSE_PLAN:
            return handleChoosePlanFlow(
              actionFactoryParams,
              submissionResponse,
            );
          case FlowType.OWNER:
            return handleOwnerFlow(actionFactoryParams);
          case FlowType.PREVIEW:
            return handlePreviewFlow(actionFactoryParams);
          case FlowType.SINGLE_SESSION:
            return handleSingleSessionFlow(
              actionFactoryParams,
              submissionResponse,
            );
        }
      }
    }
  };
}

function getCurrentFlow(
  state: FormState,
  wixSdkAdapter: WixOOISDKAdapter,
): FlowType {
  if (wixSdkAdapter.isOwner() && !wixSdkAdapter.isPreviewMode()) {
    return FlowType.OWNER;
  } else if (
    state.selectedPaymentOptionId === ReservedPaymentOptionIds.BuyAPricingPlan
  ) {
    return FlowType.CHOOSE_PLAN;
  } else if (wixSdkAdapter.isPreviewMode()) {
    return FlowType.PREVIEW;
  } else {
    return FlowType.SINGLE_SESSION;
  }
}

export async function handleChoosePlanFlow(
  { getControllerState, context: { wixSdkAdapter } }: CreateActionParams,
  submissionResponse: SubmissionResponse,
) {
  const [state, setState] = getControllerState();
  setState({
    status: FormStatus.PROCESSING_BOOK_REQUEST,
  });
  const { service, slotAvailability, editorContext } = state;
  setFieldsValuesInStorage(wixSdkAdapter, submissionResponse);
  if (editorContext.isDummy) {
    await wixSdkAdapter.navigateToPricingPlanPreview();
  } else {
    await purchasePricingPlan(service, wixSdkAdapter, slotAvailability);
  }
}

export async function handleOwnerFlow({
  getControllerState,
  context: { t },
}: CreateActionParams) {
  const [, setState] = getControllerState();
  setState(
    getOwnerSubmitDialog({
      contentText: t('app.dialog.owner-submit.content'),
      confirmButtonText: t('app.dialog.owner-submit.confirm-button'),
    }),
  );
}
export async function handlePreviewFlow({
  context: { wixSdkAdapter },
}: CreateActionParams) {
  await wixSdkAdapter.navigateToBookingsWithSuffix();
}

export async function handleSingleSessionFlow(
  {
    getControllerState,
    internalActions: { errorHandlers },
    context: { biLogger, wixSdkAdapter, formApi, reportError, experiments },
  }: CreateActionParams,
  submissionResponse: SubmissionResponse,
) {
  const [state, setState] = getControllerState();
  const {
    pricingPlanDetails,
    service,
    businessInfo,
    couponInfo,
    slotAvailability,
    selectedPaymentOptionId,
  } = state;
  setState({
    status: FormStatus.PROCESSING_BOOK_REQUEST,
  });
  const isUoUBookReferrerEnabled = experiments.enabled(
    'specs.bookings.UoUBookReferrer',
  );
  const referrer = isUoUBookReferrerEnabled
    ? wixSdkAdapter.getUrlQueryParamValue(BookingsQueryParams.REFERRER)
    : undefined;
  setFieldsValuesInStorage(wixSdkAdapter, submissionResponse);
  const selectedPlan = pricingPlanDetails?.plans?.find(
    (plan) => plan?.paidPlan?.orderId === selectedPaymentOptionId,
  );
  const { formInfo, sendSmsReminder } = mapSubmissionToPartialBookRequest(
    submissionResponse.submission,
    service,
    businessInfo,
  );

  try {
    const bookingResponse = await formApi.book({
      service,
      formInfo,
      slotAvailability,
      selectedPlan,
      sendSmsReminder,
      appliedCoupon: couponInfo.appliedCoupon,
      externalUserId: referrer,
    });

    biLogger?.report(
      bookingsContactInfoSaveSuccess({
        smsNotificationRequest: sendSmsReminder,
      }),
    );

    return wixSdkAdapter.navigateToBookingsCheckout(
      bookingResponse.booking!,
      widgetDefaults.pageName,
    );
  } catch (error) {
    errorHandlers.addError(error as BookErrorType);
    setState({ status: FormStatus.IDLE });
    biLogger?.report(
      bookingsPaymentMethodSelectionNextClicked({
        errorMessage: JSON.stringify(error),
      }),
    );
    reportError(error as BookErrorType);
  }
}

function getOwnerSubmitDialog({
  contentText,
  confirmButtonText,
}: {
  contentText: string;
  confirmButtonText: string;
}): Partial<FormState> {
  return {
    dialog: {
      type: DialogType.OwnerSubmit,
      props: {
        isOpen: true,
        contentText,
        confirmButtonText,
      },
    },
  };
}

function purchasePricingPlan(
  service: Service,
  wixSdkAdapter: WixOOISDKAdapter,
  slotAvailability: SlotAvailability,
) {
  const planIds = service.payment.pricingPlanInfo?.pricingPlans.map(
    (plan) => plan.id,
  );
  const { referral } = getQueryParams(wixSdkAdapter);
  const timezone = getSessionValues(
    wixSdkAdapter,
    BookingsQueryParams.TIMEZONE,
  );

  return wixSdkAdapter.navigateToPricingPlan({
    redirectTo: { sectionId: 'Booking Form', relativePath: '' },
    planIds: planIds!,
    maxStartDate: slotAvailability.slot?.startDate!,
    queryParams: {
      referral,
      timezone,
      service: service.id,
    },
  });
}

export function getQueryParams(
  wixSdkAdapter: WixOOISDKAdapter,
): {
  [key: string]: string;
} {
  const referral = wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.REFERRAL,
  );
  return { referral };
}
